/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { setHaTSCookie } from './HaTS.js';
import css from './HaTS.mobile.scss';
import { withVariants } from 'LaquesisEOL/withVariants';
import withTrack from 'HOCs/withTrack/withTrack';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { hats } from 'Actions/hats';
import { paramsToQueryString } from 'Helpers/url';
import { getLoggedUser } from 'Selectors/user';
import classNames from 'classnames';
import { Heading } from 'panamera-react-ui';
import Button from 'Components/Button/Button';

export class HaTS extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        hatsTitle: PropTypes.object.isRequired,
        hatsCancelButton: PropTypes.object.isRequired,
        hatsAcceptButton: PropTypes.object.isRequired,
        track: PropTypes.func.isRequired,
        actionHatsAvailable: PropTypes.func
    }

    constructor() {
        super();
        this.state = {
            animations: css.animateDown
        };
    }

    componentDidMount() {
        const { track } = this.props;

        track('banner_show', { origin: 'hats_home' });
    }

    accept = () => {
        const { track, user } = this.props;
        const url = `/hats?${paramsToQueryString({ user_id: user.id, platform: 'pwa_mobile' })}`;

        track('banner_accept', { origin: 'hats_home' });
        this.updateCookie();

        window.location.href = url;
    }

    cancel = () => {
        const { track } = this.props;

        track('banner_decline', { origin: 'hats_home' });
        this.updateCookie();
    }

    updateCookie = () => {
        const { actionHatsAvailable } = this.props;

        const animationTimeout = 1000;

        setHaTSCookie();
        this.setState({ animations: css.animateUp });
        setTimeout(() => {
            actionHatsAvailable(false);
        }, animationTimeout);
    }

    render() {
        const { hatsTitle, hatsCancelButton, hatsAcceptButton } = this.props;
        const { animations } = this.state;

        return (
            <div className={ classNames(css.survey, animations) }>
                <div className={ css.title }>
                    <Heading type="body2">{ hatsTitle }</Heading>
                </div>
                <div className={ css.buttons }>
                    <Button icon={ null } onClick={ this.cancel }>
                        { hatsCancelButton }
                    </Button>
                    <Button icon={ null } onClick={ this.accept }>
                        { hatsAcceptButton }
                    </Button>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => ({
    user: getLoggedUser(state)
});

export const mapDispatchToProps = ({
    actionHatsAvailable: hats
});

export default withVariants('PAN-29889', {
    b: compose(
        withTrack,
        connect(mapStateToProps, mapDispatchToProps)
    )(HaTS),
    'else': () => {
        return null;
    }
});

