import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withTrack from 'HOCs/withTrack/withTrack';
import css from './BannerCMC.APP_TARGET.scss';
import withRouter from 'HOCs/withRouter';
import { WEBP_TYPE } from 'Constants/images';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';

export class BannerCMC extends React.PureComponent {
    static propTypes = {
        track: PropTypes.func.isRequired,
        bannerImage: PropTypes.string.isRequired,
        router: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.props.track('cmc_show');
    }

    handleClick = () => {
        this.props.track('cmc_select');
        this.props.router.push('/cashmycar/get-started');
    };

    render() {
        return (
            <div className={ css.container }>
                <div className={ css.button } onClick={ this.handleClick } />
                <ImageWrapper
                    fileName={ this.props.bannerImage }
                    types={ WEBP_TYPE }
                    className={ css.image } />
            </div>
        );
    }
}

export default compose(
    withTrack,
    withRouter
)(BannerCMC);
