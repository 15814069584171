/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { css as uicss } from 'panamera-react-ui';
import { FormattedMessage as Translation } from 'react-intl';
import classNames from 'classnames';
import NoResults from 'Components/NoResults/NoResults';
import CategoryBrowsing from 'Components/CategoryBrowsing/CategoryBrowsing.mobile';
import FloatingPills from 'Components/FloatingPills/FloatingPills.mobile';
import css from '../Home.mobile.scss';
import Listing from './HomeListing';
import HaTS from 'Components/HaTS/HaTS.APP_TARGET';
import { userIsLogged } from 'Selectors/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import BannerCMC from './BannerCMC/BannerCMC';
import { showCMCBannerSelector } from 'Selectors/bannerCMC';
import withCookies from 'HOCs/withCookies/withCookies';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { BROWSE_CATEGORY_SHOW } from 'Constants/tracking';
import { fileNames } from 'Constants/imageUrls';
import { FLOATING_BTN_TYPES } from 'Components/FloatingPills/constants';
import { trustTagHash } from 'Constants/listing';
import TrustTagBottomSheetAsync from 'Components/TrustTagBottomSheet/TrustTagBottomSheetAsync.APP_TARGET';
import AutosHomePageWidgets from '../../../olxautos/components/AutosHomePageWidgets/AutosHomePageWidgets';
import { EvaluationConstants } from 'olx-autos-landing-page';
import SITE_CODES from 'Constants/siteCodes';
import CookieManager from 'Helpers/cookies';
import { configSelector } from 'Selectors/config';
import { BANNER_CAROUSEL_WIDGETS } from '../../../pages/Listing/ListingOld/ListingPageContent/Constant';
import { getShowBannerCarousel, getCarouselHomePageData } from 'Selectors/carousel';
import ListingCarousel from '../../../pages/Listing/ListingOld/ListingCarousel/ListingCarousel.mobile';
import { EXPIRATION_DATE, RESET_COOKIE_VALUE_LIMIT } from '../../../constants/carousel';
import { isOLXAutosOperationalRegion } from 'Components/Listing/components/helpers';
import { getCityLocationId } from 'Helpers/locations';
import AsyncNewsCarousel from '../../../olxautos/components/AutosHomePageWidgets/AsyncNewsCarousel';

const { grid } = uicss;

export class HomeContent extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        isFetchingItems: PropTypes.bool,
        onViewItem: PropTypes.func,
        location: PropTypes.object,
        enabledRealImpressions: PropTypes.bool,
        onLoadNextPage: PropTypes.func,
        renderBanners: PropTypes.func,
        adIdsToUpdate: PropTypes.array,
        feedsTitle: PropTypes.node,
        onClickPost: PropTypes.func,
        showLoadMore: PropTypes.bool,
        userIsLogged: PropTypes.bool.isRequired,
        hatsAvailable: PropTypes.bool,
        preNotification: PropTypes.bool,
        router: PropTypes.object,
        showCMCBanner: PropTypes.bool.isRequired,
        config: PropTypes.shape({
            get: PropTypes.func
        }),
        track: PropTypes.func,
        extraTrackAttr: PropTypes.object.isRequired,
        handleIconClick: PropTypes.func,
        carouselHomePage: PropTypes.object,
        locationId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        dataTestId: PropTypes.string
    };

    static defaultProps = {
        items: [],
        renderBanners: () => {},
        adIdsToUpdate: [],
        experiments: {},
        track: () => {},
        locationId: ''
    };

    constructor(props) {
        super(props);

        const { onClickPost, config } = props;

        this.state = {
            counter: 1
        };

        this.autoScroll = config.get('enableAutoScrollListing');
        this.translations = {
            title: <Translation id="HaTSTitle" />,
            cancelButton: <Translation id="HaTSCancelButton" />,
            acceptButton: <Translation id="HaTSAcceptButton" />
        };
        this.floatingButtons = [
            {
                href: '/post',
                type: FLOATING_BTN_TYPES.SELL,
                text: <Translation id="sell" />,
                onClick: onClickPost
            }
        ];
    }

    componentDidMount() {
        const cookiehomePageBanner = this.getCookieValue('home_page_banner');

        if (!cookiehomePageBanner || (cookiehomePageBanner && RESET_COOKIE_VALUE_LIMIT === Number(cookiehomePageBanner))) {
            CookieManager.createCookie('home_page_banner', 1, EXPIRATION_DATE);
        }
        else {
            CookieManager.createCookie('home_page_banner', Number(cookiehomePageBanner) + 1, EXPIRATION_DATE);
        }

        this.categoryImpressionList = [];
        window.addEventListener('beforeunload', this.fireCategoryBrowsingShow);
    }

    componentWillUnmount() {
        this.fireCategoryBrowsingShow();
        window.removeEventListener(
            'beforeunload',
            this.fireCategoryBrowsingShow
        );
    }

    getCookieValue(cookieName) {
        return CookieManager.readCookie(cookieName);
    }

    fireCategoryBrowsingShow = () =>
        this.props.track(BROWSE_CATEGORY_SHOW, {
            impressions: this.categoryImpressionList
        });

    addCategoryImpression = categoryName => {
        if (!this.categoryImpressionList.includes(categoryName)) {
            this.categoryImpressionList.push(categoryName);
        }
    };

    render() {
        const {
            items,
            isFetchingItems,
            router,
            location,
            onClickPost,
            preNotification,
            hatsAvailable,
            renderBanners,
            adIdsToUpdate,
            showCMCBanner,
            onLoadNextPage,
            enabledRealImpressions,
            onViewItem,
            feedsTitle,
            showLoadMore,
            config,
            userIsLogged,
            extraTrackAttr,
            handleIconClick,
            carouselHomePage,
            locationId,
            dataTestId
        } = this.props;

        const { title, cancelButton, acceptButton } = this.translations;
        const homeSectionClassName
            = preNotification
            && !hatsAvailable
            && router.getCurrentLocation().pathname === '/'
                ? css.homeWithPreNotification
                : css.homeWithoutPreNotification;
        const { SELLER_STATE_COOKIE, EVALUATION_STATES } = EvaluationConstants;
        const seller_state = CookieManager.readCookie(SELLER_STATE_COOKIE);
        const siteCode = config.get('siteCode');
        const autosHomeEvalutaionState
            = [
                EVALUATION_STATES.VALUATION_DONE,
                EVALUATION_STATES.APPOINTMENT_CREATED
            ].includes(seller_state)
            && [
                SITE_CODES.OLX_ID,
                SITE_CODES.OLX_AR,
                SITE_CODES.OLX_CO,
                SITE_CODES.OLX_PE,
                SITE_CODES.OLX_EC
            ].includes(siteCode);
        const showAutosHomePageWidget
            = !!config.get('showAutosHomePageWidget') || autosHomeEvalutaionState;
        const cookiehomePageBanner = this.getCookieValue('home_page_banner');
        const autosRegionCheckEnabled = (siteCode === SITE_CODES.OLX_IN);

        return (
            <section className={ homeSectionClassName } data-test-id={ dataTestId }>
                {hatsAvailable && userIsLogged && (
                    <HaTS
                        hatsTitle={ title }
                        hatsCancelButton={ cancelButton }
                        hatsAcceptButton={ acceptButton }
                    />
                )}
                {
                    (carouselHomePage?.enabled
                            && carouselHomePage?.widgetName?.pwa_mobile && (Number(cookiehomePageBanner) % Number(carouselHomePage.modulus_lhs_value)) === Number(carouselHomePage.modulus_rhs_value))
                        ? <ListingCarousel
                            widgetToFetch={ carouselHomePage?.widgetName?.pwa_mobile }
                        />
                        : (showAutosHomePageWidget && ((autosRegionCheckEnabled && isOLXAutosOperationalRegion(config, locationId.toString())) || !autosRegionCheckEnabled) && <AutosHomePageWidgets handleIconClick={ handleIconClick } />)
                }
                <div className={ grid.row }>
                    {renderBanners('top', adIdsToUpdate)}
                    <div className={ css.categoryContainer }>
                        <CategoryBrowsing
                            location={ location }
                            onClickFloatingPill={ onClickPost }
                            addCategoryImpression={ this.addCategoryImpression }
                        />
                    </div>
                    {showCMCBanner && (
                        <div
                            className={ classNames(
                                grid.col,
                                grid.xl12,
                                grid.l12,
                                grid.m12,
                                grid.s12
                            ) }
                        >
                            <BannerCMC bannerImage={ fileNames.BANNER_IMAGE } />
                        </div>
                    )}
                    <div
                        className={ classNames(
                            grid.col,
                            grid.marginContainer,
                            grid.xl12,
                            grid.l12,
                            grid.m12,
                            grid.s12,
                            css.bannersContainer
                        ) }
                    >
                        {!isFetchingItems && !items.length && <NoResults />}
                        {(isFetchingItems || items.length !== 0) && (
                            <Listing
                                items={ items }
                                onLoadNextPage={ onLoadNextPage }
                                isFetching={ isFetchingItems }
                                enabledRealImpressions={ enabledRealImpressions }
                                onViewItem={ onViewItem }
                                header={ feedsTitle }
                                location={ location }
                                visualizationType="grid"
                                itemPostPlaceholderOrigin="home"
                                fourColumns
                                showLoadMore={ showLoadMore }
                                autoScroll={ this.autoScroll }
                                extraTrackAttr={ extraTrackAttr }
                            />
                        )}
                        <div data-aut-id="mobile-news-section"><AsyncNewsCarousel /></div>
                        <FloatingPills buttons={ this.floatingButtons } />
                        {renderBanners('bottom', adIdsToUpdate)}
                    </div>
                </div>
                {this.props.location.hash.includes(trustTagHash) && (
                    <TrustTagBottomSheetAsync />
                )}
            </section>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const showBannerCarouselforHomePage = getShowBannerCarousel(configSelector(state)?.features, BANNER_CAROUSEL_WIDGETS.SHOW_BANNER_CAROUSEL_FOR_CATEGORY);
    const carouselHomePage = getCarouselHomePageData(showBannerCarouselforHomePage);
    const locationId = getCityLocationId(state.locations?.selectedLocation);

    return {
        userIsLogged: userIsLogged(state),
        hatsAvailable: state.hats.hatsAvailable,
        preNotification: state.preNotification.preNotification,
        showCMCBanner: showCMCBannerSelector(state, ownProps),
        carouselHomePage,
        locationId
    };
};

export default compose(
    withRouter,
    withCookies,
    withConfig,
    withTrack,
    connect(mapStateToProps, null)
)(HomeContent);
