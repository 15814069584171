/* global window */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import css from './BannerCarousel.scss';
import Slider from 'react-slick';
import withRouter from 'HOCs/withRouter';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';

export const BannerCarousel = ({ widgetData, router, track, showArrows = false }) => {
    const { data = {}, metadata, name } = widgetData;
    const { style } = metadata || {};
    const { items = [], sliderSettings = {}, assets = [] } = data;
    const commonSettings = {
        dots: true,
        infinite: false,
        swipeToSlide: true,
        focusOnSelect: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: css.dotsClass
    };

    const settings = {
        ...commonSettings,
        ...sliderSettings
    };

    const slider = useRef(null);
    const handleClick = item => () => {
        if (item?.tracking) {
            item.tracking.map(value => {
                track(value.event_name, value);
            });
        }
        if (window.open && item.target === '_blank') {
            window.open(item.action, '_blank')?.focus();
        }
        else {
            router?.push(item.action || '');
        }
    };

    function previous(ev) {
        ev.stopPropagation();
        slider.current.slickPrev();
    }

    function next(ev) {
        ev.stopPropagation();
        slider.current.slickNext();
    }

    function renderArrowAsset(assets, previous, next) {
        if (assets.length) {
            return assets[0].items.map(arrow => <span data-aut-id={ arrow.type === 'leftArrow' ? 'slider-arrow-previous' : 'slider-arrow-next' } dangerouslySetInnerHTML={ { __html: arrow.element } } style={ { ...assets[0].style, ...arrow.style } } onClick={ arrow.type === 'leftArrow' ? previous : next } />);
        }
        return <></>;
    }

    return (
        <div id={ name } className={ css.wrapper } style={ style } data-aut-id={ 'mobile_phones_banner_carousel_widget' }>
            {showArrows && renderArrowAsset(assets, previous, next)}
            <Slider { ...settings } ref={ slider }>
                {items.map((item, index) => {
                    return (
                        <div data-aut-id={ 'click_cta' } key={ `banner_carousel_${index}` } className={ css.bannerItem } style={ item.itemStyle }>
                            {item.type === 'image' && item.image ? (
                                <div
                                    onClick={ handleClick(item) }
                                >
                                    <ImageWrapper
                                        fileName={ item?.image?.url }
                                        style={ item?.image?.style }
                                    />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

BannerCarousel.propTypes = {
    widgetData: PropTypes.object.isRequired,
    data: PropTypes.array,
    metadata: PropTypes.object,
    track: PropTypes.func,
    name: PropTypes.string,
    staticAsseturl: PropTypes.string,
    router: PropTypes.object.isRequired,
    showArrows: PropTypes.bool
};

export default withRouter(BannerCarousel);
